















import { Component, Vue } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
  },
})
export default class PermissionError extends Vue {
  created() {
    this.$store.commit('setLoadedUser', true)
  }

  destroyed() {
    this.$store.dispatch('loadUser')
  }
}
