var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c("div", { staticClass: "limiter scrollbar" }, [
            _c(
              "div",
              {
                staticClass: "flex flex-column justify-center items-center mt6",
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/error.svg"), alt: "" },
                }),
                _c("p", { staticClass: "f2 tc light-gray fw2" }, [
                  _vm._v("Uh hu! you dont have necessary permission required."),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }